 <template>
	<el-dialog top="20vh" title="添加司机" width="500px" :visible.sync="this_is_show" @close="close" :modal="false">
        <el-form status-icon label-width="0px" label-position="left" style="margin-top:20px">
			<el-form-item>
				<el-input v-model="form.user_tel" placeholder="请输入司机手机号"  auto-complete="new-password"  clearable>
					<el-button style="width:100px" slot="prepend">司机手机号</el-button>
					<el-button  @click="se_driver_info" type="success" slot="append" style="width:112px;background-color:#409EFF;color:black;border-radius: 0;">搜索</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="form.name" placeholder="司机姓名" auto-complete="new-password"  disabled>
					<el-button style="width:100px" slot="prepend">司机姓名</el-button>
				</el-input>
			</el-form-item>
			<el-form-item >
				<el-select class="el_input" v-model="form.freight_allocation_mode" clearable style="width:460px" placeholder="请选择分配方式">
					<el-option label="司机优先" value="1"></el-option>
					<el-option label="车队长优先" value="2"></el-option>
				</el-select>
			</el-form-item>
            <el-form-item>
                <div class="button">
                     <!-- <el-button @click="dialogFormVisible=false">解除绑定</el-button> -->
                     <el-button type="primary" @click="set_sub_price_confirm">立即提交</el-button>
                </div>
               
           </el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			truck_owner_num:String,
		},
		data() {
			return {
				form:{
					user_tel:'',//司机手机
               		name:'',//司机姓名
					freight_allocation_mode:'',//分配方式
				},
               a:7777,
				//页面是否显示
				this_is_show:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.this_is_show=false
				}else {
                    this.this_is_show=true
				}
			},
		},
		methods: {
            set_sub_price_confirm(){
                //修改数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_bind_add_by_admin',
						truck_owner_num:this.truck_owner_num,
						driver_num:this.form.user_num,
						freight_allocation_mode:this.form.freight_allocation_mode
					},callback:(data)=>{
                        this.$my.other.msg({
							type:'success',
							str:'添加成功'
					    })
                        this.this_is_show = false

						this.$emit('add_success')
					}
				})
            },
			se_driver_info(){
                this.$my.net.req({
                    data:{
                        mod:'truck_driver',
                        ctr:'driver_list_by_admin',
						...this.form
                    },callback:(data)=>{
                        this.form.name = data.list[0].app_user_info.name
                        this.form.user_num = data.list[0].app_user_info.user_num
                    }
                })
            },
		   close(){
			   this.form={
				   	user_tel:'',//司机手机
               		name:'',//司机姓名
					freight_allocation_mode:'',//分配方式
			   }
           },
		}
	}
</script>
<style lang="scss" scoped>
    .operation{
		color: rgb(18, 208, 221);
		margin-left: 5px;
	}
	.el-dialog-s{
		z-index: 11;
	}
	.under_tab{
		position: relative;
		height: 40px;
		border: 1px solid #eee;
		border-width: 0 1px 1px 1px;
	}
	.this_pay_total{
		position: absolute;
		right: 0px;
		width: 539px;
		border-left: 1px solid #eee;
		height: 40px;
		line-height: 40px;
		padding-left: 10px;
	}

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:100%;
	}
	.payee{
		height: 30px;
		width: 30px;
		font-size: 20px;
		text-align: center;
		
		border-radius: 50%;
		color:rgb(0, 0, 0);
		.shou{
			margin-top: 4px;
		}
	}
	.data{
		margin-top: 5px;
		margin-left: 10px;
	}
	.red{
		background-color: red;
	}
	.green{
		background-color: #4CD964;
	}
    .button{
        text-align: center;
    }
</style>