 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="100px">
			<el-form-item class="el_form_item" label="车队长姓名">
				<el-input class="el_input" v-model="form.name" placeholder="车队长姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车队长手机号">
				<el-input class="el_input" v-model="form.tel" placeholder="车队长手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="添加时间">
				<div class="block">
				<el-date-picker
					v-model="add_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
           </el-form-item>
		   	<el-form-item class="el_form_item" >
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<!-- <el-button type="success" @click="open_add_view">添加</el-button> -->
			</el-form-item> 
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="车队长手机号">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_owner_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车队长姓名">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_owner_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="添加时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="110">
					<template slot-scope="scope">
					    <!-- <el-button @click="driver_add_open(scope.row)" size="mini" type="text" style="margin:0">添加</el-button> -->
						<el-button @click="see_driver_list(scope.row)" size="mini" type="text" style="margin-left:10px">司机列表</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>


		<!-- 司机列表 -->
		<driverList
			:is_show="driver_list.is_show"
			:truck_owner_num="driver_list.truck_owner_num"
		></driverList>


		<!-- 添加列表 -->
		<driverAdd
			:is_show="driver_add.is_show"
			:truck_owner_num="driver_add.truck_owner_num"
		></driverAdd>
	</div>
    
</template>

<script>
	import driverList from './driver_list.vue'
	import driverAdd from './driver_add.vue'
	export default {
		components:{
			driverList,
			driverAdd
		},
		data() {
			return {
				//时间快捷搜索
				add_time:'',
			    pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//搜索条件
				form: {
					name:'',//货主手机号
					tel:'',//车队长手机号
					creat_time_start:'',
					creat_time_end:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
                agent_add:{
                    is_show:0,
                },
				//车队列表
				driver_list:{
					is_show:0,
					truck_owner_num:'',
				},
				driver_add:{
					is_show:0,
					truck_owner_num:'',
				}
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()
		},
		methods: {
			//添加司机
			driver_add_open(item){
				//打开弹出层
				this.driver_add.truck_owner_num = item.truck_owner_num
				this.driver_add.is_show++;
			},
			//查看车队长下面的司机
			see_driver_list(item){

				//打开弹出层
				this.driver_list.truck_owner_num = item.truck_owner_num
				this.driver_list.is_show++;
			},

			//清空查询条件
			ser_para_clear(){
				this.add_time = ''
				this.form={
					name:'',//货主手机号
					tel:'',//车队长手机号
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){
				if (this.add_time) {
					this.form.creat_time_start = parseInt(this.add_time[0] / 1000);
					this.form.creat_time_end = parseInt(this.add_time[1] / 1000);
				}else{
					this.form.creat_time_start = '',
					this.form.creat_time_end=''
				}
				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
                    take_over_control:1,
					data:{
						mod:'truck_driver',
						ctr:'driver_bind_list_by_admin',
						...this.form,
						...this.page,
                        need_num:1,
                        is_group_truck_owner:1,
                        is_get_truck_owner_info:1,
					},
					callback:(data)=>{
						//加载完毕
						this.list.loading=false
                        if(data.code !=0){

                        }
						//总数
						this.list.total=data.msg.max

						//预处理
						for(let item of data.msg.list){
                          	item.time_text=this.$my.other.totime(item.truck_owner_info.creat_time);
					
						}
						
						//渲染
						this.list.data=data.msg.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 190px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>