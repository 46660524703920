<template>
	<el-dialog :title="page_title" top="1vh" width="60%" :visible.sync="is_show_in_Page" >
	<el-button type="primary"  style="margin-bottom: 30px;" @click="driver_add_open()">添加</el-button>
	<el-button type="dangerous"  style="margin-bottom: 30px;" @click="whole_secure(0)">全部解绑</el-button>
		<div style="margin-top:-10px;height:500px">
			<el-table :data="list" :border="true" :stripe="true" size="small" height="100%" v-loading="loading">
				<el-table-column label="司机姓名">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.driver_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="司机手机号">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.driver_info.tel}} </div>
					</template>
				</el-table-column>
				<el-table-column label="分账模式">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.freight_allocation_mode_text}} </div>
					</template>
				</el-table-column>
				<!-- 操作行 -->
				<el-table-column label="操作" width="110">
					<template slot-scope="scope">
						<el-button  @click="driver_edit(scope.row)" size="mini" type="text">编辑</el-button>
						<el-button  @click="set_def(scope.row.id)" size="mini" type="text" style="color:red">解绑</el-button>
					</template>
				</el-table-column>
			</el-table>

		<!-- 添加列表 -->
		<driverAdd
			:is_show="driver_add.is_show"
			:truck_owner_num="driver_add.truck_owner_num"
			@add_success="init"
		></driverAdd>

		<el-dialog
			title="司机编辑"
			:visible.sync="edit.show"
			width="30%"
			:modal="false"
			>
		<el-form status-icon label-width="0px" label-position="left" style="margin-top:20px">
			<el-form-item >
				<el-select class="el_input" v-model="edit.freight_allocation_mode" clearable style="width:460px" placeholder="请选择分配方式">
					<el-option label="司机优先" value="1"></el-option>
					<el-option label="车队长优先" value="2"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="edit.show = false">取 消</el-button>
				<el-button type="primary" @click="driver_edit_sub">确 定</el-button>
			</span>
		</el-dialog>
		</div>
	</el-dialog>
</template>
<script>
	import driverAdd from './driver_add.vue'
	export default {
		components:{
			driverAdd
		},
		props:{
			truck_owner_num:String,//车队长编号
			is_show:Number,//是否显示
		},
		data() {
			return {
				//列表是否显示
				is_show_in_Page:false,

				//司机列表
				list:[],

				//加载状态
				loading: false,//loading特效

				//页面标题
				page_title:'',
				//添加司机参数
				driver_add:{
					is_show:0,
					truck_owner_num:'',
				},
				//司机编辑参数
				edit:{
					show:false,
					freight_allocation_mode:'',
					id:'',
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
					this.init();
				}
			},
		},
		methods: {
			whole_secure(n){
				if(n == this.list.length){
					this.$my.other.msg({
						type:"success",
						str:'操作成功'
					});
					this.init()
					return
				}
				//提交
			    this.$my.net.req({
					take_over_control:1,
					data:{
					mod:'truck_driver',
					ctr:'driver_unbind_by_admin',
					id:this.list[n].id,
				},
				callback:(data)=>{
					this.$my.other.msg({
						type:"success",
						str:'司机'+this.list[n].driver_info.name+'解绑成功'
					});
					n++
					this.whole_secure(n)
					},
				});
			},
			//提交司机编辑
			driver_edit_sub(){
				//提交
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_bind_edit_by_admin',
						id:this.edit.id,
						freight_allocation_mode:this.edit.freight_allocation_mode
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:"success",
							str:'编辑成功'
						});
						//清空数据
						this.edit.show = false
						this.edit.id = ''
						this.edit.freight_allocation_mode =''
						//刷新
						this.init()
					},
				});
			},
			//打开司机编辑弹出层
			driver_edit(item){
				this.edit.show = true
				this.edit.id = item.id
				this.edit.freight_allocation_mode = item.freight_allocation_mode
			},
			//添加司机
			driver_add_open(){
				//打开弹出层
				this.driver_add.truck_owner_num = this.truck_owner_num
				this.driver_add.is_show++;
			},
			set_def(id){
				//提示
				this.$my.other.confirm({
					content:"是否确认解绑该司机?",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'driver_unbind_by_admin',
								id,
							},
							callback:(data)=>{
			
								this.$my.other.msg({
									type:"success",
									str:'解绑成功'
								});
								this.init()
							},
						});
					}
				});
			},
			//初始化
			init(){
				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_bind_list_by_admin',
						need_num:1,
						is_get_driver_info:1,
                        is_get_truck_owner_info:1,
						truck_owner_num:this.truck_owner_num
					},
					callback:(data)=>{
					 for(let item of data.list){
						//分账模式
						switch(item.freight_allocation_mode){
							case '1':item.freight_allocation_mode_text="司机优先";break;
							case '2':item.freight_allocation_mode_text="车队长优先";break;
						}
					 }
					  this.list = data.list
					}
				})
			},

		}
	}
</script>

<style scoped>
	.el-dialog-s{
		z-index: 11;
		
	}
</style>